import { Record } from 'immutable';
import moment from 'moment';
import { camelCaseResponse } from '../util';
import User from './user';

class Check extends Record({
  createdAt: null,
  id: null,
  isCompleted: false,
  owner: null,
  shareCode: null,
  subtotal: null,
  tax: null,
  tip: null,
  totalPrice: null,
}) {
  static constructFromAPIResponse(apiResponse) {
    const mappedProps = camelCaseResponse(apiResponse);
    const owner = new User(camelCaseResponse(apiResponse.owner));
    return new Check(Object.assign(mappedProps, { owner }));
  }

  get formattedDate() {
    if (!this.createdAt) {
      return null;
    }
    return moment(this.createdAt).format('MMMM Do, YYYY');
  }
}

export default Check;
