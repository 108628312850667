import { Record } from 'immutable';
import { camelCaseResponse } from '../util';

class User extends Record({
  id: null,
  firstName: null,
  lastName: null,
  email: null,
  username: null,
  paymentAccounts: {},

  loggedIn: false,
  token: null,
}) {
  static constructFromAPIResponse(apiResponse) {
    return new User(camelCaseResponse(apiResponse));
  }

  get fullName() {
    return [this.firstName, this.lastName].filter(n => n).join(" ");
  }

  get venmoUsername() {
    const hasVenmo = this.paymentAccounts.venmo
    if (hasVenmo) {
      return this.paymentAccounts.venmo.username
    }

    return null;
  }
}

export const getBearerToken = response => (
  new User({ token: response.access_token })
);

export default User;
