export const SIGN_OUT = 'SIGN_OUT';
export const COPY_LINK_TO_CLIPBOARD = 'COPY_LINK_TO_CLIPBOARD';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const GET_BEARER_TOKEN_COOKIE_REQUEST =
  'GET_BEARER_TOKEN_COOKIE_REQUEST';
export const GET_BEARER_TOKEN_COOKIE_SUCCESS =
  'GET_BEARER_TOKEN_COOKIE_SUCCESS';
export const GET_BEARER_TOKEN_COOKIE_FAILURE =
  'GET_BEARER_TOKEN_COOKIE_FAILURE';

export const GET_BEARER_TOKEN_REQUEST = 'GET_BEARER_TOKEN_REQUEST';
export const GET_BEARER_TOKEN_SUCCESS = 'GET_BEARER_TOKEN_SUCCESS';
export const GET_BEARER_TOKEN_FAILURE = 'GET_BEARER_TOKEN_FAILURE';

export const GET_ALL_CHECKS_REQUEST = 'GET_ALL_CHECKS_REQUEST';
export const GET_ALL_CHECKS_SUCCESS = 'GET_ALL_CHECKS_SUCCESS';
export const GET_ALL_CHECKS_FAILURE = 'GET_ALL_CHECKS_FAILURE';

export const GET_CHECK_REQUEST = 'GET_CHECK_REQUEST';
export const GET_CHECK_SUCCESS = 'GET_CHECK_SUCCESS';
export const GET_CHECK_FAILURE = 'GET_CHECK_FAILURE';

export const CREATE_CHECK_REQUEST = 'CREATE_CHECK_REQUEST';
export const CREATE_CHECK_SUCCESS = 'CREATE_CHECK_SUCCESS';
export const CREATE_CHECK_FAILURE = 'CREATE_CHECK_FAILURE';

export const PUT_CLAIM_ITEMS_REQUEST = 'PUT_CLAIM_ITEMS_REQUEST';
export const PUT_CLAIM_ITEMS_SUCCESS = 'PUT_CLAIM_ITEMS_SUCCESS';
export const PUT_CLAIM_ITEMS_FAILURE = 'PUT_CLAIM_ITEMS_FAILURE';

export const CREATE_CHECK_ITEM_REQUEST = 'CREATE_CHECK_ITEM_REQUEST';
export const CREATE_CHECK_ITEM_SUCCESS = 'CREATE_CHECK_ITEM_SUCCESS';
export const CREATE_CHECK_ITEM_FAILURE = 'CREATE_CHECK_ITEM_FAILURE';

export const DELETE_CHECK_ITEM_REQUEST = 'DELETE_CHECK_ITEM_REQUEST';
export const DELETE_CHECK_ITEM_SUCCESS = 'DELETE_CHECK_ITEM_SUCCESS';
export const DELETE_CHECK_ITEM_FAILURE = 'DELETE_CHECK_ITEM_FAILURE';

export const JOIN_CHECK_REQUEST = 'JOIN_CHECK_REQUEST';
export const JOIN_CHECK_SUCCESS = 'JOIN_CHECK_SUCCESS';
export const JOIN_CHECK_FAILURE = 'JOIN_CHECK_FAILURE';

export const FETCH_CHECK_BY_CODE_REQUEST = 'FETCH_CHECK_BY_CODE_REQUEST';
export const FETCH_CHECK_BY_CODE_SUCCESS = 'FETCH_CHECK_BY_CODE_SUCCESS';
export const FETCH_CHECK_BY_CODE_FAILURE = 'FETCH_CHECK_BY_CODE_FAILURE';
