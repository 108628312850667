import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { createUser as createUserAction } from '../actions';

import UserInfo, { SIGN_UP_INFO } from '../components/user-info';
import { getRedirect } from '../util';

class SignUp extends Component {
  render() {
    const { user } = this.props;

    if (user.loggedIn) {
      return getRedirect(window.location.search);
    }

    return (
      <div className="signup-form col-sm-6 col-xs-12">
        <h2>Sign Up</h2>
        <p>Enter your account info to start splitting checks!</p>
        <hr />
        <UserInfo
          handleSubmit={this.props.createUser}
          submitText="Sign Up"
          type={SIGN_UP_INFO}
          user={user}
        />
        <div className="hint-text">
          Already have an account? <Link to="/login">Login here</Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  createUser: createUserAction,
})(SignUp);
