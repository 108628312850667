import React, { Component } from 'react';
import { formatCurrency } from '../util';

class Receipt extends Component {
  render() {
    const { 
      subtotal,
      tax,
      tip,
      totalPrice,
    } = this.props;

    if (! (subtotal && tax && tip && totalPrice)) {
      return null;
    }

    return [
      ['Subtotal', subtotal, 'subtotal'],
      ['Tax', tax, 'tax'],
      ['Tip', tip, 'tip'],
      ['Total Price', totalPrice, 'total-price'],
    ].map(([title, amount, className], index) => (
      <div className={`item-row ${className}`} key={index}>
        <div className="row">
          <div className="col-8">{ title }</div>
          <div className="col-4">{ formatCurrency(amount) }</div>
        </div>
      </div>
    ));
  }
}

export default Receipt;
