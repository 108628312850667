import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Alert extends Component {
  componentDidUpdate(prevProps) {
    if (
      (this.props.alert && prevProps.alert !== this.props.alert) ||
      (this.props.error && prevProps.error !== this.props.error)
    ) {
      this.addToasts();
    }
  }

  addToasts() {
    const { alert, error } = this.props;

    if (error) {
      toast.error(error);
    } else if (alert) {
      toast.info(alert);
    }
  }

  render() {
    return <ToastContainer
      position="top-center"
      autoClose={4000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable={false}
      transition={Slide}
    />;
  }
}

const mapStateToProps = state => ({
  alert: state.alert,
  error: state.error,
});

export default connect(
  mapStateToProps,
)(Alert);
