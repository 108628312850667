import { Record } from 'immutable';

class SplitQuantity extends Record({
  whole: 0,
  numerator: 0,
  denominator: 1,
}) {
  static new(item, totalSplits) {
    if (!item) {
      return new SplitQuantity({
        whole: 0,
        numerator: 0,
        denominator: totalSplits,
      });
    }

    return new SplitQuantity(item.quantity);
  }

  static fromString(str) {
    if (str === "0") {
      return new SplitQuantity();
    }

    const splits = str.split("/");
    if (splits.length === 1) {
      return new SplitQuantity({ whole: str });
    }

    return new SplitQuantity({
      numerator: splits[0],
      denominator: splits[1],
    })
  }

  get toHtmlString() {
    if (this.numerator === 0) {
      return `${this.whole}`;
    }

    const frac = `${this.numerator}&#8260;${this.denominator}`;
    if (this.whole === 0) {
      return frac;
    }

    return `${this.whole} ${frac}`;
  }

  get n() {
    return Number(this.numerator);
  }

  get d() {
    return Number(this.denominator);
  }

  get w() {
    return Number(this.whole);
  }

  get hasFractions() {
    return this.n !== 0;
  }

  get toApiInput() {
    if (this.n === 0) {
      return {
        quantity: this.w,
      };
    }

    return {
      quantity_numerator: this.n + (this.w * this.d),
      quantity_denominator: this.d,
    }
  }

  get isCustom() {
    return this.n !== 0 && this.w !== 0;
  }

  get quantityString() {
    if (this.n !== 0) {
      return `${this.n}/${this.d}`;
    }

    return `${this.w}/1`;
  }

  toString() {
    if (this.numerator === 0) {
      return `${this.whole}`;
    }

    const frac = `${this.numerator}/${this.denominator}`;
    if (this.whole === 0) {
      return frac;
    }

    return `${this.whole} ${frac}`;
  }

  reset(totalSplits = 1) {
    return new SplitQuantity({
      whole: this.whole,
      numerator: 0,
      denominator: totalSplits,
    });
  }
}

export default SplitQuantity;
