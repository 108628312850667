import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import JoinCheck from './join-check';

class Home extends Component {
  renderButton() {
    const { user } = this.props;

    if (user.loggedIn) {
      return (
        <Link className="btn btn-success btn-block" to="/new-check">
          <span className="leading-icon">
            <FontAwesomeIcon icon={faPlus} />
          </span>
          Add a new Bill to Split
        </Link>
      );
    }

    return (
      <Link to="/login" className="btn btn-primary btn-block">
        Sign in to get started!
      </Link>
    );
  }

  render() {
    return (
      <div className="container">
        <div className="inner cover">
          <h1 className="cover-heading">
            Split It App <small>(BETA)</small>
          </h1>
          <p className="lead">
            Gone are the days where you think "Who is going to pick up this
            check?" or "Will people pay me back for this food I just covered?"
            The <strong>Split It App</strong> is the one stop solution to all of
            your bill-splitting needs.
          </p>
          <hr />
          <div className="container">
            <div className="row justify-content-center">
              {this.renderButton()}
            </div>
          </div>
          <hr />
          <div className="row justify-content-center">
            <h4>OR</h4>
          </div>
          <JoinCheck />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {})(Home);
