import React from 'react';

import CheckItem from './check-item';
import SplitQuantity from '../models/split-quantity';

const CheckItems = ({
  handleClaimCheckItem,
  items,
  mySplit,
  splits,
}) => {
  const checkItems = items.toList().map((item) => {
    const mySplitItem = mySplit.items.find(splitItem => splitItem.id === item.id);

    const splitQuantity = SplitQuantity.new(mySplitItem, splits.size)
    return (
      <CheckItem
        key={`item-${item.id}`}
        handleClaimCheckItem={handleClaimCheckItem}
        item={item}
        splits={splits}
        splitQuantity={splitQuantity}
      />
    );
  });

  return (
    <>
      <div className="row item-header">
        <div className="col-sm-6 col-5">Item</div>
        <div className="col-sm-3 col-4">Unit Price</div>
        <div className="col-3">Total</div>
      </div>
      { checkItems }
    </>
  );
}

export default CheckItems;