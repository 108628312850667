import React, { Component } from 'react';

import SplitQuantity from '../models/split-quantity';

class SplitOptions extends Component {
  constructor(props) {
    super(props);

    this.handleSplitQuantityChange = this.handleSplitQuantityChange.bind(this);
    this.submitCheckItemClaim = this.submitCheckItemClaim.bind(this);

    this.toggleCustomInput = this.toggleCustomInput.bind(this);
    this.handleSplitCustomQuantityChange = this.handleSplitCustomQuantityChange.bind(this);
    this.resetInput = this.resetInput.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);

    const d = (props.splitQuantity.n === 0 ? props.splitCount : props.splitQuantity.d);
    this.state = {
      showCustomInput: this.props.splitQuantity.isCustom,
      quantityString: this.props.splitQuantity.quantityString,
      numerator: this.props.splitQuantity.n,
      denominator: d,
      whole: this.props.splitQuantity.w,
    }
  }

  handleSplitQuantityChange(event) {
    if (event.target.value === 'custom') {
      this.toggleCustomInput();
      return;
    }

    this.setState({
      quantityString: event.target.value,
    });
  }

  handleSplitCustomQuantityChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  submitCheckItemClaim(event) {
    const { handleClaimCheckItem, item } = this.props;
    let { showCustomInput, quantityString, numerator, denominator, whole } = this.state;

    let splitQuantity;
    if (!showCustomInput) {
      splitQuantity = SplitQuantity.fromString(quantityString);
    } else {
      splitQuantity = new SplitQuantity({
        numerator,
        denominator,
        whole,
      });
    }

    handleClaimCheckItem({
      checkItemId: item.id,
      splitQuantity,
    });

    event.preventDefault();
  }

  toggleCustomInput() {
    let { showCustomInput } = this.state;
    this.setState({ showCustomInput: !showCustomInput });
  }

  resetInput() {
    const { splitCount, splitQuantity } = this.props;
    const d = (splitQuantity.n === 0 ? splitCount : splitQuantity.d)

    this.setState({
      numerator: splitQuantity.n,
      denominator: d,
      whole: splitQuantity.w,
    });
  }

  onFocus(event) {
    event.target.value = ""
  }

  onBlur(event) {
    event.target.value = this.state[event.target.name];
  }

  renderShowDropdownButton() {
    const { showCustomInput } = this.state;
    if (!showCustomInput) { return null; }

    return (
      <button
        type="button"
        className="btn btn-secondary btn-sm margin-left-10"
        onClick={this.toggleCustomInput}
      >
        Show Dropdown
      </button>
    );
  }

  renderWholeQuantityInput() {
    const { showCustomInput, whole } = this.state;
    if (!showCustomInput) { return null; }

    return (
      <input
        inputMode="numeric"
        pattern="[0-9]*"
        type="number"
        name="whole"
        size={3}
        onBlur={this.onBlur}
        onChange={this.handleSplitCustomQuantityChange}
        onFocus={this.onFocus}
        value={whole}
      />
    );
  }

  renderFractionInput() {
    const { showCustomInput, numerator, denominator } = this.state;
    if (!showCustomInput) { return null; }

    return (
      <table className="frac margin-left-10">
        <tbody>
          <tr>
            <td className="numerator">
              <input
                inputMode="numeric"
                pattern="[0-9]*"
                type="number"
                name="numerator"
                size={3}
                onBlur={this.onBlur}
                onChange={this.handleSplitCustomQuantityChange}
                onFocus={this.onFocus}
                value={numerator}
              />
            </td>
          </tr>
          <tr>
            <td>
              <input
                inputMode="numeric"
                pattern="[0-9]*"
                type="number"
                name="denominator"
                size={3}
                onBlur={this.onBlur}
                onChange={this.handleSplitCustomQuantityChange}
                onFocus={this.onFocus}
                value={denominator}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderSplitOptionsDropdown() {
    const { showCustomInput, quantityString } = this.state;
    if (showCustomInput) { return null; }

    const { item, splitCount } = this.props;

    const quantityOptions = [[0, 0]];
    for(let i = 1 ; i <= item.quantity ; i++) {
      quantityOptions.push([i, `${i}/1`]);
    }
    for(let i = splitCount ; i > 1 ; i--) {
      quantityOptions.push([`1/${i}`, `1/${i}`]);
    }

    quantityOptions.push(['Custom Quantity', 'custom'])

    return (
      <select
        className="custom-select"
        id={`item-${item.id}-quantity`}
        onChange={this.handleSplitQuantityChange}
        value={quantityString}
      >
        { quantityOptions.map(([string, value]) => <option key={value} value={value}>{ string }</option>) }
      </select>
    );
  }

  renderSplitInput() {
    return (
      <div>
        { this.renderSplitOptionsDropdown() }
        { this.renderWholeQuantityInput() }
        { this.renderFractionInput() }
        { this.renderShowDropdownButton() }
      </div>
    );
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <form className="form-inline split-options row no-gutters" onClick={(e) => e.stopPropagation()} onSubmit={this.submitCheckItemClaim}>
        <div className="col-9 claim-quantity">
          { this.renderSplitInput() }
        </div>

        <div className="col-3">
          <button type="submit" className="btn btn-primary">Claim</button>
        </div>
      </form>
    );
  }
}

export default SplitOptions;
