import React from 'react';

const Spinner = ({ loading }) => {
  if (loading.size === 0) {
    return null;
  }

  return (
    <div id="spinner">
      <div className="sk-chase">
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
    </div>
  );
}

export default Spinner;