import { 
  applyMiddleware,
  createStore,
} from 'redux';

import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router'

import api from '../middleware/api';
import createRootReducer from '../reducers';

const configureStore = (initialData, history) => (
  createStore(
    createRootReducer(history),
    initialData,
    applyMiddleware(
      thunk,
      api,
      routerMiddleware(history),
    ),
  )
);

export default configureStore;
