import { Record } from 'immutable';
import { formatCurrency } from '../util';

class NewCheckItem extends Record({
  name: null,
  unitPrice: null,
  quantity: null,
}) {
  get totalPrice() {
    return this.unitPrice * this.quantity;
  }

  get totalPriceString() {
    return formatCurrency(this.totalPrice);
  }

  get unitPriceString() {
    return formatCurrency(this.unitPrice);
  }

  get toApiInput() {
    return {
      item_name: this.name,
      item_price: this.unitPrice,
      quantity: this.quantity,
    };
  }
}

export default NewCheckItem;
