import { createBrowserHistory } from 'history'

import devStore from './dev';
import prodStore from './prod';

let configureStore;

if (process.env.NODE_ENV === 'production') {
  configureStore = prodStore;
} else {
  configureStore = devStore;
}

export const history = createBrowserHistory()

export default configureStore;
