import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getAllChecks as getAllChecksAction } from '../actions';

class MyChecks extends Component {
  constructor(props) {
    super(props);
    this.props.getAllChecks();
  }

  renderChecks() {
    const { checks } = this.props;

    return checks.toList().map((check) => (
      <tr key={`check-${check.id}`}>
        <td>
          <Link to={`/checks/${check.id}`}>{check.restaurantName}</Link>
        </td>
        <td>
          {check.isOwner && (
            <span class="badge badge-pill badge-secondary badge-small">
              My Bill
            </span>
          )}
          {check.isCompleted && (
            <span class="badge badge-pill badge-success badge-small">
              Completed
            </span>
          )}
        </td>
        <td>{moment(check.createdAt).format('MMMM D, YYYY')}</td>
      </tr>
    ));
  }

  render() {
    const { checks } = this.props;

    if (!checks) {
      return null;
    }

    return (
      <div className="col-sm-6 col-xs-12">
        <h3>My Bills</h3>
        <Link className="btn btn-success btn-block" to="/new-check">
          <span className="leading-icon">
            <FontAwesomeIcon icon={faPlus} />
          </span>
          Add a new Bill to Split
        </Link>
        <Link className="btn btn-primary btn-block" to="/join">
          Join an existing Bill
        </Link>
        <table className="table">
          <thead>
            <tr>
              <th>Restaurant Name</th>
              <th></th>
              <th>Date Added</th>
            </tr>
          </thead>
          <tbody>{this.renderChecks()}</tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  checks: state.checks,
  user: state.user,
});

export default connect(mapStateToProps, {
  getAllChecks: getAllChecksAction,
})(MyChecks);
