import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'

// Container Imports
import Account from './account';
import Alert from './alert';
import Check from './check';
import MyChecks from './my-checks';
import JoinCheck from './join-check';
import NewCheck from './new-check';
import Home from './home';
import Login from './login';
import NavBar from './navbar';
import SignUp from './sign-up';

// Component Imports
import Spinner from '../components/spinner';

class Router extends Component {
  renderAuthenticatedRoute(path, component) {
    const { user } = this.props;

    if (!user.loggedIn) {
      return <Redirect to="/login" />;
    }

    return (
      <Route exact path={path} component={component} />
    );
  }

  render() {
    const { history, loading } = this.props;

    return (
      <ConnectedRouter history={history}>
        <div className="app">
          <NavBar />
          <div>
            <Alert />
          </div>
          <Spinner loading={loading} />
          <div className="container app-container">
            <div className="row justify-content-center">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/sign-up" component={SignUp} />

                <Route exact path='/checks/:check_id' component={Check} />
                <Route exact path='/join' component={JoinCheck} />
                { this.renderAuthenticatedRoute('/account', Account) }
                { this.renderAuthenticatedRoute('/checks', MyChecks) }
                { this.renderAuthenticatedRoute('/new-check', NewCheck) }
              </Switch>
            </div>
          </div>
        </div>
      </ConnectedRouter>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.loading,
  user: state.user,
});

export default connect(
  mapStateToProps,
)(Router);
