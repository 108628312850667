import { OrderedMap, Record } from 'immutable';
import { camelCaseResponse } from '../util';

class CheckSummary extends Record({
  id: null,
  createdAt: null,
  restaurantName: null,
  isOwner: false,
  isCompleted: false,
  totalPrice: null,
}) {
  static constructFromAPIResponse(apiResponse) {
    return new CheckSummary(camelCaseResponse(apiResponse));
  }
}

export const CheckSummaries = (response) =>
  new OrderedMap(
    response.map((c) => [c.id, CheckSummary.constructFromAPIResponse(c)]),
  );

export default CheckSummary;
