import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { connect } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { signOut as signOutAction } from '../actions';

const NavBar = (props) => {
  const { signOut, user } = props;

  const { pathname, search } = useLocation();
  const redirectTo = `${pathname}${search}`;

  const renderNavMenu = () => {
    if (!user.id) {
      return (
        <>
          <NavLink
            to={`/login?redirectTo=${redirectTo}`}
            activeClassName="active"
            className="nav-item nav-link"
          >
            Sign In
          </NavLink>
          <NavLink
            to={`/sign-up?redirectTo=${redirectTo}`}
            activeClassName="active"
            className="nav-item nav-link"
          >
            Sign Up
          </NavLink>
        </>
      );
    }

    return (
      <>
        <NavLink
          to="/checks"
          activeClassName="active"
          className="nav-item nav-link"
        >
          My Bills
        </NavLink>
        <NavLink
          to="/account"
          activeClassName="active"
          className="nav-item nav-link"
        >
          My Account
        </NavLink>
        <NavDropdown title={`Signed in as ${user.fullName}`}>
          <NavDropdown.Item onClick={signOut}>Sign out</NavDropdown.Item>
        </NavDropdown>
      </>
    );
  };

  return (
    <Navbar bg="dark" expand="md" variant="dark">
      <Link to="/" className="navbar-brand">
        Split It App <small>(BETA)</small>
      </Link>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">{renderNavMenu()}</Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  signOut: signOutAction,
})(NavBar);
