import axios from 'axios';
import { get, snakeCase } from 'lodash';
import { Map } from 'immutable';
import { stringify } from 'query-string';

export const API_REQUEST = 'API_REQUEST';
export const API_RESPONSE = 'API_RESPONSE';
export const API_ERROR = 'API_ERROR';

export const SPLIT_API = 'SPLIT_API';

const formatApiParams = params => (
  new Map(params)
    .mapKeys(k => snakeCase(k))
    .toObject()
);

export const apiUrl = (args, endpoint) => (args ? `${endpoint}/?${stringify(formatApiParams(args))}` : `${endpoint}/`);

const apiRequest = ({
  args,
  body: requestBody,
  endpoint,
  method,
  models,
  token,
}) => {
  const url = apiUrl(args, endpoint);
  const data = requestBody ? formatApiParams(requestBody) : null;
  const headers = token ? { Authorization: `Bearer ${token}` } : null;

  return axios({
    data,
    headers,
    method,
    url,
  }).then((response) => {
    const { data } = response;
    const middlewareResponse = {};

    if (models === undefined) {
      return data;
    }

    Object.keys(models).forEach((key) => {
      let parsedResponse;
      // Try if it's a function
      try {
        parsedResponse = models[key](data);
      // Otherwise it's an immutable record / class
      } catch (e) {
        parsedResponse = new models[key](data);
      }

      middlewareResponse[key] = parsedResponse;
    });

    return middlewareResponse;
  });
};

const callApi = (next, action, token) => {
  const {
    args,
    body,
    endpoint,
    method,
    models,
    types,
  } = action[SPLIT_API];

  const [requestType, successType, failureType] = types;

  next({
    api: API_REQUEST,
    action,
    next,
    type: requestType,
  });
  return apiRequest({
    args,
    body,
    endpoint,
    method,
    models,
    token,
  }).then(
    (response) => (
      next({
        api: API_RESPONSE,
        action,
        next,
        response,
        type: successType,
      })
    ),
    (error) => (
      next({
        api: API_ERROR,
        action,
        body,
        next,
        response: get(error.response, 'data') || { message: 'Something bad happened' },
        status: get(error.response, 'status'),
        type: failureType,
      })
    ),
  );
};

export const token = (store) => {
  const state = store.getState();
  return state.user.token;
};

export default store => next => (action) => {
  if (typeof action[SPLIT_API] === 'undefined') {
    return next(action);
  }

  return callApi(next, action, token(store));
};