import { Record } from 'immutable';

class Restaurant extends Record({
  id: null,
  name: null,
}) {
  static constructFromEmbeddedAPIResponse(apiResponse) {
    return new Restaurant(apiResponse.restaurant);
  }
}

export default Restaurant;