import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getBearerToken as getBearerTokenAction } from '../actions';

import UserInfo, { LOGIN_INFO } from '../components/user-info';
import { getRedirect } from '../util';

class Login extends Component {
  render() {
    const { user } = this.props;

    if (user.loggedIn) {
      return getRedirect(window.location.search);
    }

    return (
      <div className="login-form col-sm-6 col-xs-12">
        <h2>Sign In</h2>
        <p>Sign in to your account to start spliting checks!</p>
        <hr />
        <UserInfo
          handleSubmit={this.props.getBearerToken}
          submitText="Sign In"
          type={LOGIN_INFO}
          user={user}
        />
        <div className="hint-text">
          Don't have an account? <Link to="/sign-up">Sign up here</Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  getBearerToken: getBearerTokenAction,
})(Login);
