import moment from 'moment';
import Cookies from 'universal-cookie';
import { push } from 'connected-react-router';

import * as ActionTypes from './types';
import * as API from './api';
import { API_ERROR } from '../middleware/api';

export const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://www.splitit.dev'
    : 'http://localhost:3000';

export const getCheck = (checkId, splitAuthCode) => (dispatch) => {
  dispatch(API.fetchCheck(checkId, splitAuthCode)).then(({ api }) => {
    if (api === API_ERROR) {
      dispatch(push('/'));
    }
  });
};

export const getAllChecks = () => (dispatch) => {
  dispatch(API.fetchAllChecks());
};

export const saveBearerTokenToCookies = (accessToken) => () => {
  const cookies = new Cookies();
  const expires = moment().add(90, 'd').toDate();

  cookies.set('access_token', accessToken, { expires, path: '/' });
};

export const getBearerToken = (args) => (dispatch, getState) => {
  const { username, password } = args;
  dispatch(API.fetchOAuthToken(username, password)).then(() => {
    const { user } = getState();

    const state = getState();
    if (state.user.loggedIn) {
      dispatch(saveBearerTokenToCookies(user.token));
      dispatch(API.fetchMyAccountInfo());
    }
  });
};

export const getBearerTokenCookie = () => (dispatch) => {
  dispatch({ type: ActionTypes.GET_BEARER_TOKEN_COOKIE_REQUEST });

  const cookies = new Cookies();
  const token = cookies.get('access_token');
  let actionType;
  if (token) {
    actionType = ActionTypes.GET_BEARER_TOKEN_COOKIE_SUCCESS;
  } else {
    actionType = ActionTypes.GET_BEARER_TOKEN_COOKIE_FAILURE;
  }

  return dispatch({ type: actionType, token });
};

export const createCheck = (args) => (dispatch, getState) => {
  dispatch(API.createCheck(args)).then(({ api }) => {
    if (api !== API_ERROR) {
      const { check } = getState();
      dispatch(push(`/checks/${check.id}`));
    }
  });
};

export const createUser = (args) => (dispatch, getState) => {
  dispatch(API.createUser(args)).then(() => {
    const state = getState();
    if (state.user.id) {
      const { username, password } = args;
      dispatch(API.fetchOAuthToken(username, password));
    }
  });
};

export const updateUser = (args) => (dispatch) => {
  dispatch(API.updateUser(args));
};

export const signOut = () => (dispatch) => {
  const cookies = new Cookies();
  cookies.remove('access_token', { path: '/' });
  dispatch({ type: ActionTypes.SIGN_OUT });
};

export const copyLinkToClipboard = (shareCode) => (dispatch) => {
  var tempShareCodeInput = document.createElement('input');
  tempShareCodeInput.style = 'position: absolute; left: -1000px; top: -1000px';
  tempShareCodeInput.value = `${BASE_URL}/join?share_code=${shareCode}`;
  document.body.appendChild(tempShareCodeInput);
  tempShareCodeInput.select();
  document.execCommand('copy');
  document.body.removeChild(tempShareCodeInput);

  dispatch({ type: ActionTypes.COPY_LINK_TO_CLIPBOARD });
};

export const claimCheckItem =
  ({ checkId, checkItemId, splitQuantity, splitAuthCode }) =>
  (dispatch) => {
    dispatch(
      API.claimCheckItems({
        checkId,
        splitAuthCode,
        items: [
          {
            item_id: checkItemId,
            ...splitQuantity.toApiInput,
          },
        ],
      }),
    );
  };

export const joinCheck =
  ({ nickname, shareCode }) =>
  (dispatch) => {
    dispatch(API.joinCheck({ nickname, shareCode })).then(
      ({ api, response }) => {
        if (api !== API_ERROR) {
          dispatch(
            push(
              `/checks/${response.check_id}?auth_code=${response.auth_code}`,
            ),
          );
        }
      },
    );
  };

export const fetchCheckByShareCode = (shareCode) => (dispatch) => {
  dispatch(API.fetchCheckByShareCode(shareCode));
};

export const initializeApp = () => (dispatch, getState) => {
  dispatch(getBearerTokenCookie());

  const { user } = getState();
  if (user.loggedIn) {
    dispatch(API.fetchMyAccountInfo());
  }
};
