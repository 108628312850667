import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

import { formatCurrency } from '../util';

import Split from './split';

class MySplit extends Component {
  constructor(props) {
    super(props);

    this.toggleShowSplit = this.toggleShowSplit.bind(this);

    this.state = {
      showSplit: false,
    }
  }

  toggleShowSplit() {
    const show = this.state.showSplit;
    this.setState({
      showSplit: !show,
    });
  }

  renderIcon() {
    const icon = this.state.showSplit ? faChevronUp : faChevronDown

    return (
      <span className="trailing-icon">
        <FontAwesomeIcon icon={icon} />
      </span>
    );
  }

  renderSplit() {
    const { mySplit } = this.props;

    if (!this.state.showSplit) {
      return null;
    }

    return (
      <>
        <Split split={mySplit} />
      </>
    );
  }

  render() {
    const { mySplit } = this.props;

    return (
      <div className="margin-bottom-10 clickable" onClick={this.toggleShowSplit}>
        <div className="row item-header">
          <div className="col-8">
            <div className="split-color" style={ mySplit.displayColors }></div>
            { mySplit.name }
          </div>
          <div className="col-4">
            { formatCurrency(mySplit.totalPrice) }
            { this.renderIcon() }
          </div>
        </div>
        { this.renderSplit() }
      </div>
    );
  }
}

export default MySplit;