import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import {
  fetchCheckByShareCode as fetchCheckByShareCodeAction,
  joinCheck as joinCheckAction,
} from '../actions';

const JoinCheck = (props) => {
  const {
    joinCheckAction,
    fetchCheckByShareCodeAction,
    check,
    restaurant,
    splits,
    user,
  } = props;
  const urlParams = new URLSearchParams(window.location.search);

  const shareCodeParam = urlParams.get('share_code') || '';
  const hasShareCodeParam = !!shareCodeParam;

  const [shareCode, setShareCode] = useState(shareCodeParam);
  const [nickname, setNickname] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    joinCheck();
  };

  const joinCheck = useCallback(
    () => joinCheckAction({ shareCode, nickname }),
    [shareCode, nickname, joinCheckAction],
  );

  useEffect(() => {
    if (user && user.id && hasShareCodeParam) {
      return joinCheck();
    }
  }, [user, hasShareCodeParam, joinCheck]);

  useEffect(() => {
    if (shareCodeParam) {
      return fetchCheckByShareCodeAction(shareCodeParam);
    }
  }, [shareCodeParam, fetchCheckByShareCodeAction]);

  const restaurantName = restaurant?.name;
  const checkOwnerName = check?.owner?.fullName;

  const title = restaurantName
    ? `Split the bill for ${restaurantName}`
    : 'Join a bill to Split It';

  const splitPeople = splits
    ? splits
        .filter((s) => s.claimed)
        .filter((s) => s?.userId !== check?.owner?.id)
        .map((s) => s.name)
        .toArray()
        .map(([_, name]) => name.trim())
    : [];
  const splitPeopleString = splitPeople.length
    ? splitPeople.reduce(
        (a, b, i, array) =>
          a +
          (i < array.length - 1
            ? ', '
            : array.length > 2
            ? ', and '
            : ' and ') +
          b,
      )
    : '';

  return (
    <div className="join-check container">
      {restaurantName && (
        <Helmet>
          <title>{title} - Split It App</title>
          <meta property="og:title" content={title} />
        </Helmet>
      )}
      <div className="row justify-content-center">
        {checkOwnerName && restaurantName ? (
          <h5>
            <strong>{checkOwnerName}</strong> has invited you to split the bill
            for <strong>{restaurantName}</strong>
          </h5>
        ) : (
          <h5>{title}</h5>
        )}
      </div>
      <div className="row justify-content-center">
        <p>
          {checkOwnerName && restaurantName
            ? `Joining this bill will allow you to claim each item you had and pay ${checkOwnerName} back.`
            : `Joining a bill will allow you to claim each item you had and pay your friends back.`}
        </p>
      </div>
      {splitPeopleString && (
        <div className="row justify-content-center">
          <h6>{splitPeopleString} already joined this bill.</h6>
        </div>
      )}
      <div className="row justify-content-center">
        <form onSubmit={handleSubmit}>
          <div className="form-row container">
            {!hasShareCodeParam && (
              <input
                className="form-control mb-2 mr-sm-2"
                id="shareCode"
                name="shareCode"
                onChange={(e) => setShareCode(e.target.value)}
                placeholder="Share Code for the Bill (Ask the host for the code)"
                type="text"
                value={shareCode}
              />
            )}
            {!user.loggedIn && (
              <input
                className="form-control mb-2 mr-sm-2"
                id="nickname"
                name="nickname"
                onChange={(e) => setNickname(e.target.value)}
                placeholder="Your Name"
                type="text"
                value={nickname}
              />
            )}
            <div className="col-3"></div>
            <div className="col-6">
              <button type="submit" className="btn btn-primary btn-block">
                Join bill to Split It
              </button>
            </div>
            <div className="col-3"></div>
          </div>
        </form>
      </div>
      <div className="row justify-content-center">
        {!user.loggedIn && (
          <small>
            If you are not signed in, you will not be able to view this bill
            after you close this tab.
          </small>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  check: state.check,
  restaurant: state.restaurant,
  splits: state.splits,
  user: state.user,
});

export default connect(mapStateToProps, {
  joinCheckAction,
  fetchCheckByShareCodeAction,
})(JoinCheck);
