import { OrderedMap, Record, List } from 'immutable';
import { camelCaseResponse, formatCurrency } from '../util';
import SplitQuantity from './split-quantity';

class CheckItem extends Record({
  id: null,
  name: null,
  unitPrice: null,
  quantity: null,
  totalPrice: null,
  fullyClaimed: null,
  splits: null,
}) {
  static constructFromAPIResponse(apiResponse) {
    const splits = new List(apiResponse.splits.map(s => CheckItemSplit.constructFromAPIResponse(s)));
    const mappedProps = camelCaseResponse(apiResponse);
    return new CheckItem(Object.assign(mappedProps, { splits }));
  }

  get totalPriceString() {
    return formatCurrency(this.totalPrice);
  }

  get unitPriceString() {
    return formatCurrency(this.unitPrice);
  }
}

class CheckItemSplit extends Record({
  name: null,
  splitId: null,
  quantity: null,
}) {
  static constructFromAPIResponse(apiResponse) {
    return new CheckItemSplit({
      name: apiResponse.name,
      splitId: apiResponse.split_id,
      quantity: new SplitQuantity(apiResponse.quantity),
    });
  }
}

export const CheckItems = response => (
  new OrderedMap(response.check_items.map(c => [c.id, CheckItem.constructFromAPIResponse(c)]))
);

export default CheckItem;
