import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

// Store
import configureStore, { history } from './js/store';
import { initializeApp } from './js/actions';

// Models
import User from './js/models/user';

// CSS Imports
import './css/app.css';
import './css/spinner.css';

// Container Imports
import Router from './js/containers/router';

// Initialize Axios
import axios from 'axios';
axios.defaults.headers.post['Content-Type'] = 'application/json';

// Initialize Store
const initialStore = {
  user: new User(),
};
const store = configureStore(initialStore, history);
store.dispatch(initializeApp());

render(
  <Provider store={store}>
    <Router history={history} />
  </Provider>,
  document.getElementById('root'),
);
