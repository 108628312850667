import React from 'react';
import { camelCase, mapKeys } from 'lodash';
import { Redirect } from 'react-router';

export const camelCaseResponse = (response) =>
  mapKeys(response, (v, k) => camelCase(k));

export const formatCurrency = (value) => {
  if (typeof value === String) {
    return `$${value}`;
  }

  return `$${parseFloat(value).toFixed(2)}`;
};

export const getRedirect = (search) => {
  const urlParams = new URLSearchParams(search);

  const redirectTo = urlParams.get('redirectTo');
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return <Redirect to="/checks" />;
};
