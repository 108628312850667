import React, { useState } from 'react';
import cx from 'classnames';

import SplitOptions from './split-options';
import SplitQuantity from '../models/split-quantity';

const CheckItem = ({ handleClaimCheckItem, item, splits, splitQuantity }) => {
  const [showSplitOptions, setShowSplitOptions] = useState(false);

  const itemSplits = item.splits.map((s) => (
    <div
      className="badge badge-primary split-bubble"
      style={splits.get(s.splitId).displayColors}
      key={`item-split-${s.splitId}`}
    >
      {s.name}{' '}
      <span className="badge badge-light">
        {new SplitQuantity(s.quantity).toString()}
      </span>
    </div>
  ));

  const claimCheckItem = (...args) => {
    handleClaimCheckItem(...args);
    setShowSplitOptions(false);
  };

  const classNames = cx([
    'clickable',
    'item-row',
    {
      'fully-claimed': item.fullyClaimed,
    },
  ]);

  return (
    <div
      className={classNames}
      onClick={() => setShowSplitOptions((prev) => !prev)}
    >
      <div className="row">
        <div className="col-sm-6 col-5">{item.name}</div>
        <div className="col-sm-3 col-4">
          {item.unitPriceString} ({item.quantity})
        </div>
        <div className="col-3">{item.totalPriceString}</div>
      </div>
      <div className="row">
        <div className="col-12">{itemSplits}</div>
      </div>
      <SplitOptions
        handleClaimCheckItem={claimCheckItem}
        item={item}
        show={showSplitOptions}
        splitCount={splits.size}
        splitQuantity={splitQuantity}
      />
    </div>
  );
};

export default CheckItem;
