import { connectRouter } from 'connected-react-router';
import { OrderedMap, Stack } from 'immutable';
import { combineReducers } from 'redux';

// Action Types
import * as ActionTypes from '../actions/types';
import { API_ERROR, API_REQUEST, API_RESPONSE } from '../middleware/api';

// Models
import Check from '../models/check';
import Restaurant from '../models/restaurant';
import Split from '../models/split';
import User from '../models/user';

const alert = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.COPY_LINK_TO_CLIPBOARD:
      return 'Copied link to clipboard!';
    case ActionTypes.CREATE_USER_SUCCESS:
      return 'Successfully updated your user info!';
    case ActionTypes.UPDATE_USER_SUCCESS:
      return 'Successfully updated your user info!';
    case ActionTypes.CREATE_CHECK_SUCCESS:
      return 'Successfully created the bill!';
    case ActionTypes.SIGN_OUT:
      return 'You have been successfully signed out!';
    default:
      break;
  }
  return null;
};

const check = (state = new Check(), action) => {
  switch (action.type) {
    case ActionTypes.GET_CHECK_SUCCESS:
    case ActionTypes.CREATE_CHECK_SUCCESS:
    case ActionTypes.FETCH_CHECK_BY_CODE_SUCCESS:
      return action.response.check;
    case ActionTypes.SIGN_OUT:
      return new Check();
    default:
      break;
  }
  return state;
};

const checks = (state = new OrderedMap(), action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_CHECKS_SUCCESS:
      return action.response.checks;
    case ActionTypes.SIGN_OUT:
      return new OrderedMap();
    default:
      break;
  }
  return state;
};

const checkItems = (state = new OrderedMap(), action) => {
  switch (action.type) {
    case ActionTypes.GET_CHECK_SUCCESS:
    case ActionTypes.PUT_CLAIM_ITEMS_SUCCESS:
    case ActionTypes.CREATE_CHECK_SUCCESS:
      return action.response.checkItems;
    case ActionTypes.SIGN_OUT:
      return new OrderedMap();
    default:
      break;
  }
  return state;
};

const error = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_USER_FAILURE:
      switch (action.response.code) {
        case 'missing_new_user_fields':
          return 'You did not enter all of the required fields.';
        case 'passwords_do_not_match':
          return 'Your passwords did not match.';
        case 'email_already_taken':
          return 'There is already an account with this email address.';
        case 'username_already_taken':
          return 'This username is already taken';
        default:
          return 'There was an error creating your user.';
      }
    case ActionTypes.UPDATE_USER_FAILURE:
      switch (action.response.code) {
        case 'email_already_taken':
          return 'There is already an account with this email address.';
        case 'username_already_taken':
          return 'This username is already taken';
        default:
          return 'There was an error updating your user information.';
      }
    case ActionTypes.PUT_CLAIM_ITEMS_FAILURE:
      switch (action.response.code) {
        case 'invalid_check_item_quantity':
          return 'You cannot claim more than the available quantity.';
        default:
          return 'There was an error claiming this item';
      }
    case ActionTypes.GET_BEARER_TOKEN_FAILURE:
      return 'There was an error logging in. Make sure you are using the right credentials.';
    case ActionTypes.GET_CHECK_FAILURE:
      return 'There was an error accessing this bill. Please try again.';
    case ActionTypes.JOIN_CHECK_FAILURE:
      switch (action.response.code) {
        case 'join_check_nickname_taken':
          return 'There was an error joining this bill. Try a different nickname.';
        case 'join_check_request_invalid':
          return 'There was an error joining this bill. Be sure to add a nickname.';
        default:
          return 'There was an error joining this bill. Make sure you have the right code.';
      }
    case ActionTypes.CREATE_CHECK_FAILURE:
      return 'Error creating bill. Make sure you added a restaurant name, tax, and tip.';
    default:
      break;
  }
  return null;
};

const loading = (state = new Stack(), action) => {
  switch (action.api) {
    case API_REQUEST:
      return state.push(true);
    case API_ERROR:
    case API_RESPONSE:
      return state.pop();
    default:
      break;
  }
  return state;
};

const mySplit = (state = new Split(), action) => {
  switch (action.type) {
    case ActionTypes.GET_CHECK_SUCCESS:
    case ActionTypes.PUT_CLAIM_ITEMS_SUCCESS:
    case ActionTypes.CREATE_CHECK_SUCCESS:
      return action.response.mySplit;
    case ActionTypes.SIGN_OUT:
      return new Split();
    default:
      break;
  }
  return state;
};

const restaurant = (state = new Restaurant(), action) => {
  switch (action.type) {
    case ActionTypes.GET_CHECK_SUCCESS:
    case ActionTypes.CREATE_CHECK_SUCCESS:
    case ActionTypes.FETCH_CHECK_BY_CODE_SUCCESS:
      return action.response.restaurant;
    case ActionTypes.SIGN_OUT:
      return new Restaurant();
    default:
      break;
  }
  return state;
};

const splits = (state = new OrderedMap(), action) => {
  switch (action.type) {
    case ActionTypes.GET_CHECK_SUCCESS:
    case ActionTypes.FETCH_CHECK_BY_CODE_SUCCESS:
    case ActionTypes.PUT_CLAIM_ITEMS_SUCCESS:
    case ActionTypes.CREATE_CHECK_SUCCESS:
      return action.response.splits;
    case ActionTypes.SIGN_OUT:
      return new OrderedMap();
    default:
      break;
  }
  return state;
};

const user = (state = new User(), action) => {
  switch (action.type) {
    case ActionTypes.GET_USER_SUCCESS:
    case ActionTypes.CREATE_USER_SUCCESS:
    case ActionTypes.UPDATE_USER_SUCCESS:
      const user = action.response.user;
      return state.merge({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        paymentAccounts: user.paymentAccounts,
        username: user.username,
      });
    case ActionTypes.GET_BEARER_TOKEN_SUCCESS:
      return state.merge({
        loggedIn: true,
        token: action.response.user.token,
      });
    case ActionTypes.GET_BEARER_TOKEN_COOKIE_SUCCESS:
      return state.merge({
        loggedIn: true,
        token: action.token,
      });
    case ActionTypes.GET_USER_FAILURE:
      return state.merge({
        loggedIn: false,
      });
    case ActionTypes.SIGN_OUT:
      return new User();
    default:
      break;
  }
  return state;
};

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    alert,
    check,
    checks,
    checkItems,
    error,
    loading,
    mySplit,
    restaurant,
    splits,
    user,
  });

export default createRootReducer;
