import { SPLIT_API } from '../middleware/api';
import * as ActionTypes from './types';

import Check from '../models/check';
import { CheckItems } from '../models/check-item';
import { CheckSummaries } from '../models/check-summary';
import Restaurant from '../models/restaurant';
import { MySplit, Splits } from '../models/split';
import User, { getBearerToken } from '../models/user';

// const API_URL =
//   process.env.NODE_ENV === "production"
//     ? "https://split-it-api.fly.dev"
//     : "http://localhost:4000";
const API_URL = 'https://split-it-api.fly.dev';
const BASE_URL = `${API_URL}/api/v1`;

const CHECK_MODELS = {
  check: Check.constructFromAPIResponse,
  mySplit: MySplit,
  splits: Splits,
  checkItems: CheckItems,
  restaurant: Restaurant.constructFromEmbeddedAPIResponse,
};

export const createUser = (body) => ({
  [SPLIT_API]: {
    types: [
      ActionTypes.CREATE_USER_REQUEST,
      ActionTypes.CREATE_USER_SUCCESS,
      ActionTypes.CREATE_USER_FAILURE,
    ],
    endpoint: `${BASE_URL}/users`,
    method: 'post',
    body,
    models: { user: User.constructFromAPIResponse },
  },
});

export const updateUser = (body) => ({
  [SPLIT_API]: {
    types: [
      ActionTypes.UPDATE_USER_REQUEST,
      ActionTypes.UPDATE_USER_SUCCESS,
      ActionTypes.UPDATE_USER_FAILURE,
    ],
    endpoint: `${BASE_URL}/users/me`,
    method: 'put',
    body,
    models: { user: User.constructFromAPIResponse },
  },
});

export const fetchOAuthToken = (username, password) => ({
  [SPLIT_API]: {
    types: [
      ActionTypes.GET_BEARER_TOKEN_REQUEST,
      ActionTypes.GET_BEARER_TOKEN_SUCCESS,
      ActionTypes.GET_BEARER_TOKEN_FAILURE,
    ],
    endpoint: `${BASE_URL}/oauth/token`,
    method: 'post',
    body: {
      grant_type: 'password',
      username,
      password,
    },
    models: { user: getBearerToken },
  },
});

export const fetchMyAccountInfo = () => ({
  [SPLIT_API]: {
    types: [
      ActionTypes.GET_USER_REQUEST,
      ActionTypes.GET_USER_SUCCESS,
      ActionTypes.GET_USER_FAILURE,
    ],
    endpoint: `${BASE_URL}/users/me`,
    method: 'get',
    models: { user: User.constructFromAPIResponse },
  },
});

export const fetchAllChecks = () => ({
  [SPLIT_API]: {
    types: [
      ActionTypes.GET_ALL_CHECKS_REQUEST,
      ActionTypes.GET_ALL_CHECKS_SUCCESS,
      ActionTypes.GET_ALL_CHECKS_FAILURE,
    ],
    endpoint: `${BASE_URL}/checks`,
    method: 'get',
    models: { checks: CheckSummaries },
  },
});

export const fetchCheck = (checkId, splitAuthCode = '') => ({
  [SPLIT_API]: {
    types: [
      ActionTypes.GET_CHECK_REQUEST,
      ActionTypes.GET_CHECK_SUCCESS,
      ActionTypes.GET_CHECK_FAILURE,
    ],
    args: {
      split_auth_code: splitAuthCode,
    },
    endpoint: `${BASE_URL}/checks/${checkId}`,
    method: 'get',
    models: CHECK_MODELS,
  },
});

export const claimCheckItems = ({ checkId, items, splitAuthCode }) => ({
  [SPLIT_API]: {
    types: [
      ActionTypes.PUT_CLAIM_ITEMS_REQUEST,
      ActionTypes.PUT_CLAIM_ITEMS_SUCCESS,
      ActionTypes.PUT_CLAIM_ITEMS_FAILURE,
    ],
    body: {
      items,
      split_auth_code: splitAuthCode,
    },
    endpoint: `${BASE_URL}/checks/${checkId}/items/claim`,
    method: 'put',
    models: CHECK_MODELS,
  },
});

export const createCheck = (body) => ({
  [SPLIT_API]: {
    types: [
      ActionTypes.CREATE_CHECK_REQUEST,
      ActionTypes.CREATE_CHECK_SUCCESS,
      ActionTypes.CREATE_CHECK_FAILURE,
    ],
    body,
    endpoint: `${BASE_URL}/checks/`,
    method: 'post',
    models: CHECK_MODELS,
  },
});

export const joinCheck = ({ shareCode, nickname }) => ({
  [SPLIT_API]: {
    types: [
      ActionTypes.JOIN_CHECK_REQUEST,
      ActionTypes.JOIN_CHECK_SUCCESS,
      ActionTypes.JOIN_CHECK_FAILURE,
    ],
    args: {
      share_code: shareCode,
      nickname: nickname,
    },
    endpoint: `${BASE_URL}/checks/join`,
    method: 'get',
  },
});

export const fetchCheckByShareCode = (shareCode) => ({
  [SPLIT_API]: {
    types: [
      ActionTypes.FETCH_CHECK_BY_CODE_REQUEST,
      ActionTypes.FETCH_CHECK_BY_CODE_SUCCESS,
      ActionTypes.FETCH_CHECK_BY_CODE_FAILURE,
    ],
    args: {
      share_code: shareCode,
    },
    endpoint: `${BASE_URL}/checks/details`,
    method: 'get',
    models: {
      check: Check.constructFromAPIResponse,
      splits: Splits,
      checkItems: CheckItems,
      restaurant: Restaurant.constructFromEmbeddedAPIResponse,
    },
  },
});
