import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateUser as updateUserAction } from '../actions';

import UserInfo, { ACCOUNT_INFO } from '../components/user-info';

class Account extends Component {
  render() {
    const { user } = this.props;

    return (
      <div className="signup-form col-sm-6 col-xs-12">
        <h2>My Account</h2>
        <hr />
        <UserInfo
          handleSubmit={this.props.updateUser}
          submitText="Update Account Info"
          type={ACCOUNT_INFO}
          user={user}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(
  mapStateToProps, {
    updateUser: updateUserAction,
  }
)(Account);
