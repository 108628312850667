import { sumBy } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createCheck as createCheckAction } from '../actions';

import NewCheckItem from '../models/new-check-item';
import { formatCurrency } from '../util';

import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NewCheck = (props) => {
  const { createCheck, user } = props;

  const [restaurantName, setRestaurantName] = useState('');
  const [tax, setTax] = useState(0);
  const [tip, setTip] = useState(0);
  const [splitCount, setSplitCount] = useState(0);
  const [items, setItems] = useState([]);
  const [newItemName, setNewItemName] = useState('');
  const [inputError, setInputError] = useState('');

  const getFloat = (str) => {
    return parseFloat((parseFloat(str) || 0).toFixed(2));
  };

  const addNewItem = () => {
    const itemStr = newItemName.trim();

    const quantityRegex = /^\d+/;
    const quantity = parseInt(
      (itemStr.match(quantityRegex) ? itemStr.match(quantityRegex)[0] : 1) ?? 1,
    );

    const totalPriceRegex = /\$?\d*(\.\d+)?$/;
    const totalPrice = getFloat(
      itemStr.match(totalPriceRegex)
        ? itemStr.match(totalPriceRegex)[0].replace('$', '')
        : 0,
    );

    const name = itemStr
      .replace(quantityRegex, '')
      .replace(totalPriceRegex, '')
      .trim();

    if (!name || !quantity || !totalPrice) {
      setInputError('Be sure to have an item name and price');
      return;
    }

    const unitPrice = getFloat(totalPrice / quantity);

    const newItem = new NewCheckItem({
      name,
      quantity,
      unitPrice,
    });

    setItems((prev) => [...prev, newItem]);
    setNewItemName('');
    setInputError('');
  };

  const removeItemAtIndex = (index) => {
    setItems((prev) => prev.filter((_, idx) => idx !== index));
  };

  const submitForm = (event) => {
    if (newItemName.trim()) {
      addNewItem();
    }

    createCheck({
      restaurant_name: restaurantName,
      tax: getFloat(tax),
      tip: getFloat(tip),
      split_count: splitCount,
      items: items.map((i) => i.toApiInput),
    });

    event.preventDefault();
  };

  const renderDecimalInputField = (
    id,
    placeholder,
    prependWith,
    setStateFn,
  ) => {
    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <div className="input-group-text">{prependWith}</div>
        </div>
        <input
          className="form-control"
          id={id}
          name={id}
          onChange={(e) => setStateFn(e.target.value)}
          placeholder={placeholder}
          required
          step="0.01"
          inputMode="decimal"
          pattern="[0-9]*"
          type="number"
        />
      </div>
    );
  };

  const renderItems = () => {
    if (items.length === 0) {
      return null;
    }

    return (
      <div className="container">
        <div className="row item-header">
          <div className="col-4">Item</div>
          <div className="col-4">Unit Price</div>
          <div className="col-4">Total</div>
        </div>
        {items.map((item, index) => (
          <div className="item-row" key={index}>
            <div className="row">
              <div className="col-4">{item.name}</div>
              <div className="col-4">
                {item.unitPriceString} ({item.quantity})
              </div>
              <div className="col-2">{item.totalPriceString}</div>
              <div className="col-2">
                <div
                  className="btn btn-danger"
                  onClick={() => removeItemAtIndex(index)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderNewItemInput = () => {
    return (
      <form
        className="margin-top-10"
        onSubmit={(e) => {
          e.preventDefault();
          addNewItem();
          document.getElementById('newItemName').focus();
        }}
      >
        <div className="form-group row">
          <div>
            Examples:
            <pre>
              Nachos $15
              <br />2 Beef Burger $24.50
              <br />3 Latte $9
            </pre>
          </div>
          <input
            className={
              'form-control col-10' + (inputError ? ' is-invalid' : '')
            }
            id="newItemName"
            name="newItemName"
            placeholder="(#) Item Name ($)Price"
            onChange={(e) => setNewItemName(e.target.value)}
            autoComplete="false"
            type="text"
            value={newItemName}
          />
          <div className="col-2">
            <button type="submit" className="btn btn-success">
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
          {inputError && <div className="invalid-feedback">{inputError}</div>}
        </div>
      </form>
    );
  };

  const renderTotals = () => {
    const subtotal = sumBy(items, (item) => getFloat(item.totalPrice));
    const totalPrice = subtotal + getFloat(tax) + getFloat(tip);

    return [
      ['Subtotal', subtotal, 'subtotal'],
      ['Total Price', totalPrice, 'total-price'],
    ].map(([title, amount, className], index) => (
      <div className={`item-row ${className}`} key={index}>
        <div className="row">
          <div className="col-8">{title}</div>
          <div className="col-4">{formatCurrency(amount)}</div>
        </div>
      </div>
    ));
  };

  const renderVenmoDisclaimer = () => {
    if (user.venmoUsername) {
      return null;
    }

    return (
      <small className="form-text text-muted margin-bottom-10">
        Be sure to add your Venmo username on the "My Account" page so people
        can pay you!
      </small>
    );
  };

  return (
    <div className="col-sm-6 col-xs-12">
      <h4>New Bill</h4>
      {renderVenmoDisclaimer()}
      <div className="form-group">
        <input
          className="form-control"
          id="restaurantName"
          name="restaurantName"
          onChange={(e) => setRestaurantName(e.target.value)}
          placeholder="Restaurant Name"
          autoComplete="false"
          required
          type="text"
        />
      </div>
      <div className="form-group">
        {renderDecimalInputField('tax', 'Tax', '$', setTax)}
      </div>
      <div className="form-group">
        {renderDecimalInputField('tip', 'Tip', '$', setTip)}
      </div>
      <div className="form-group">
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">#</div>
          </div>
          <input
            className="form-control"
            id="splitCount"
            name="splitCount"
            onChange={(e) => setSplitCount(e.target.value)}
            placeholder="Number of people on bill"
            required
            inputMode="decimal"
            pattern="[0-9]*"
            type="number"
          />
        </div>
        <small id="splitCountHelp" className="form-text text-muted">
          Entering "Number of people on bill" makes the splitting process
          easier.
        </small>
      </div>
      <h6>Add Bill Items</h6>
      {renderItems()}
      <div className="container">{renderNewItemInput()}</div>
      {renderTotals()}
      <div className="form-group margin-top-10">
        <button type="button" className="btn btn-primary" onClick={submitForm}>
          Create Bill
        </button>
        <small className="form-text text-muted">
          Make sure the totals match your receipt. You will not be able to
          modify your bill once it is created.
        </small>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  createCheck: createCheckAction,
})(NewCheck);
