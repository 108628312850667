import React, { Component } from 'react';

export const ACCOUNT_INFO = 'ACCOUNT_INFO';
export const LOGIN_INFO = 'LOGIN_INFO';
export const SIGN_UP_INFO = 'SIGN_UP_INFO';

class UserInfo extends Component {
  constructor(props) {
    super(props);

    this.optionalFields = [];

    const { user, type } = this.props;
    switch(type) {
      case LOGIN_INFO:
        this.state = {
          password: '',
          username: '',
        };
        break;
      case SIGN_UP_INFO:
        this.state = {
          email: '',
          firstName: '',
          lastName: '',
          password: '',
          passwordConfirmation: '',
          username: '',
        };
        break;
      default:
        this.state = {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          password: user.password,
          passwordConfirmation: user.password,
          username: user.username,
        };
        this.optionalFields = ['password'];
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  handleInputChange(event) {
    const key = event.target.id;
    this.setState({
      [key]: event.target.value,
    });
  }

  submitForm(event) {
    const { handleSubmit } = this.props;
    handleSubmit(this.state);
    event.preventDefault();
  }

  renderInputField(id, placeholder, type, defaultValue = '') {
    const required = !this.optionalFields.includes(id);

    let label = null;
    if (this.props.type === ACCOUNT_INFO) {
      label = <label htmlFor={id}>{ placeholder }</label>
    }

    let inputMode = ""
    let autoCapitalize = "on"
    let autoCorrect = "on"
    let autoComplete = ""
    if (type === "email") {
      inputMode = "email";
      autoCapitalize = "off";
      autoCorrect = "off";
      autoComplete = "email";
    }

    return (
      <>
        { label }
        <input
          className="form-control"
          defaultValue={defaultValue}
          id={id}
          name={id}
          onChange={this.handleInputChange}
          placeholder={placeholder}
          required={required}
          type={type}
          inputMode={inputMode}
          autoCapitalize={autoCapitalize}
          autoCorrect={autoCorrect}
          autoComplete={autoComplete}
        />
      </>
    );
  }

  renderUserInfoInput() {
    const { type, user } = this.props;
    if (type === LOGIN_INFO) {
      return null;
    }

    return (
      <>
        <div className="form-row">
          <div className="form-group col-sm-6 col-xs-6">
            { this.renderInputField('firstName', 'First Name', 'text', user.firstName) }
          </div>
          <div className="form-group col-sm-6 col-xs-6">
            { this.renderInputField('lastName', 'Last Name', 'text', user.lastName) }
          </div>
        </div>
        <div className="form-group">
          { this.renderInputField('email', 'Email', 'email', user.email) }
        </div>
        <div className="form-group">
          {
            (this.props.type === ACCOUNT_INFO) ?
              <label htmlFor="venmoUsername">Venmo Username</label> :
              null
          }
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">@</div>
            </div>
            <input
              className="form-control"
              defaultValue={user.venmoUsername}
              id="venmoUsername"
              name="venmoUsername"
              onChange={this.handleInputChange}
              placeholder="Venmo Username"
              type="text"
            />
          </div>
          <small id="venmoHelp" className="form-text text-muted">
            This will be used by other users to send you payments.
          </small>
        </div>
      </>
    );
  }

  renderPasswordConfirmation() {
    const { type } = this.props;
    if (type !== SIGN_UP_INFO) {
      return null;
    }

    return (
      <div className="form-group">
        { this.renderInputField('passwordConfirmation', 'Confirm Password', 'password') }
      </div>
    );
  }

  render() {
    const {
      submitText,
      user,
    } = this.props;

    return (
      <form onSubmit={this.submitForm}>
        <div className="form-group">
          { this.renderInputField('username', 'Username', 'text', user.username) }
        </div>
        { this.renderUserInfoInput(user) }
        <div className="form-group">
          { this.renderInputField('password', 'Password', 'password') }
        </div>
        { this.renderPasswordConfirmation() }
        <div className="form-group">
          <button type="submit" className="btn btn-primary">{ submitText }</button>
        </div>
      </form>
    );
  }
}

export default UserInfo;
